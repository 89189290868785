
const verifyDSDafaultState = {
    fileInput: null,
	fileWithDS: null,
    fileSignedData: null,
	fileInputName: "",
	fileWithDSName: "",
	fileInputSelected: false,
	fileWithDSSelected: false,
    verifyResults: null,
    verifyResultsForQR: null,
    divId: "",
    differenceSignatureType: null,
    verifyResultsForPrint: null
}

const verifyDSInputReducer = (state = verifyDSDafaultState, { type, payload }) => {

    switch (type) {
    	case "SET_VERIFY_INPUT_FILE": {
		    return {
		        ...state,
		        fileInput: payload,
		        fileInputName: payload.name,
		        fileInputSelected: true,
		    }
		}

        case "SET_VERIFY_RESULT_DATA_FOR_PRINT": {
            return {
                ...state,
                verifyResultsForPrint: payload
            }
        }

        case "SET_VERIFY_RESULT_DATA": {
            return {
                ...state,
                verifyResults: payload.data,
                divId: payload.div
            }
        }

        case "SET_FILE_SIGNED_DATA": {
            return {
                ...state,
                fileSignedData: payload
            }
        }

        case "CLEAR_FILE_SIGNED_DATA": {
            return {
                ...state,
                fileSignedData: null
            }
        }

        case "CLEAR_VERIFY_RESULT_DATA": {
            return {
                ...state,
                verifyResults: payload,
                divId: ""
            }
        }

        case "SET_VERIFY_RESULT_FOR_QR": {
            return {
                ...state,
                verifyResultsForQR: payload
            }
        }

        case "CLEAR_VERIFY_RESULT_FOR_QR": {
            return {
                ...state,
                verifyResultsForQR: null
            }
        }

		case "CLEAR_VERIFY_INPUT_FILE": {
		    return {
		        ...state,
		        fileInput: null,
		        fileInputName: "",
		        fileInputSelected: false,
		    }
		}

        case 'SET_VERIFY_DS_INPUT_FILE': {
            return {
                ...state,
                fileWithDS: payload,
                fileWithDSName: payload.name,
                fileWithDSSelected: true
            }
        }

        case 'CLEAR_VERIFY_DS_INPUT_FILE': {
            return {
                ...state,
                fileWithDS: null,
                fileWithDSName: "",
                fileWithDSSelected: false
            }
        }

        case 'SET_SIGNATURE_DIFFERENCE_TYPE': {
            return {
                ...state,
                differenceSignatureType: payload
            }
        }

        case 'CLEAR_SIGNATURE_DIFFERENCE_TYPE': {
            return {
                ...state,
                differenceSignatureType: null
            }
        }

        default:
            return state;
    }

}

export default verifyDSInputReducer